import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

// Import your components here
import Home from "./components/home/home";
import OrganizationDetails from "./components/companyDetails";
import AdministratorDetails from "./components/administrator";
import ClaimPhoneNumber from "./components/claimPhoneNumber";
import CallHandlingOptions from "./components/selectQueues";
import AddAgents from "./components/addAgents";
import ConnectYourCRM from "./components/connectYourCRM";
import SuccessPage from "./components/successPage";
import NotFound from "./components/notFound";
import CsvUploadPage from "./components/addAgents/csvForm";
import SalesForce from "./components/connectYourCRM/salesForcePage";
import Cookie from "./components/footer/cookies";
import Terms from "./components/footer/terms";
import Privacy from "./components/footer/privacy";
import Refreshform from "./components/home/refreshpopup";
import './components/routepop.css';

const NavigationHandler = ({ setShowModal }) => {
  const location = useLocation();

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      if (location.pathname !== "/") {
        setShowModal(true);
        window.history.pushState(null, null, window.location.href);
      }
    };

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      sessionStorage.setItem("redirectToHome", "true");
      event.returnValue = ''; // This is required for some browsers to show a confirmation dialog
    };

    window.addEventListener("popstate", handlePopState);
    window.addEventListener("beforeunload", handleBeforeUnload);

    window.history.pushState(null, null, window.location.href);

    if (sessionStorage.getItem("redirectToHome") === "true") {
      sessionStorage.removeItem("redirectToHome");
      if (location.pathname !== "/") {
        setShowModal(true);
        window.history.pushState(null, null, window.location.href);
      }
    }

    return () => {
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location.pathname, setShowModal]);

  return null;
};

const Modal = ({ showModal, handleClose }) => {
  if (!showModal) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <p>Are you sure you want to go back?</p>
        <button onClick={handleClose}>Cancel</button>
        <button onClick={() => window.location.replace("/")}>OK</button>
      </div>
    </div>
  );
};

export default function App() {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
    window.history.pushState(null, null, window.location.href); // prevent back navigation
  };

  return (
    <Router>
      <NavigationHandler setShowModal={setShowModal} />
      <Modal showModal={showModal} handleClose={handleClose} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/organization-details" element={<OrganizationDetails />} />
        <Route path="/claim-phone-number" element={<ClaimPhoneNumber />} />
        <Route path="/administrator-details" element={<AdministratorDetails />} />
        <Route path="/call-handling-options" element={<CallHandlingOptions />} />
        <Route path="/add-agents" element={<AddAgents />} />
        <Route path="/connect-your-crm" element={<ConnectYourCRM />} />
        <Route path="/success-page" element={<SuccessPage />} />
        <Route path="/bulk-user-creation" element={<CsvUploadPage />} />
        <Route path="/sales-force-form" element={<SalesForce />} />
        <Route path="/cookies" element={<Cookie />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
