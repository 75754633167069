import React, { useState } from "react";
import {axiosInstance, axiosInstanceCSVDownload, axiosInstanceCSVUpload } from "../../services/axiosInstance";
import { endpoints } from "../../services/endpoints";
import { Buffer } from "buffer";
import {
  companyDetailsContext,
  bulkUserCsvContext,
  addAgentsContext,
  notFoundStatusContext,homePageContext,
} from "../../context";
import MainTheme from "../mainTemplate";
import "./addAgents.css";
import { ErrorServiceLogger } from "../../services/errorServiceLogger";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import SnackBar from "../../utilities/snackBar";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import CircularProgress from "@mui/material/CircularProgress";

Buffer.from("anything", "base64");

const CsvUploadPage = () => {
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState([]);
  let bulkUserCsvStatus = React.useContext(bulkUserCsvContext);
  let addAgents = React.useContext(addAgentsContext);
  const companyDetails = React.useContext(companyDetailsContext);
  let notFoundStatus = React.useContext(notFoundStatusContext);
  const [showDetails, setShowDetails] = useState(false);
  const [successMessage, setSucessMessage] = useState({});
  //const [successMessage, setSuccessMessage] = useState({"message":""});
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [loading, setLoading] = useState(false);;
  const homePageDetails = React.useContext(homePageContext);
  const [filesDropped, setFilesDropped] = useState(false);
  const [uploadClicked, setUploadClicked] = useState(false);
  React.useEffect(() => {
    notFoundStatus.status = false;
  }, []);

  const copyToClipboard = (text) => {
    const tempInput = document.createElement("input");
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    setSnackBarOpen(true);
  };

  React.useEffect(() => {
    if (successMessage.message) {
      bulkUserCsvStatus.status = true;
    }
  }, [bulkUserCsvStatus, successMessage]);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
    if (!showDetails) {
      window.open(
        "https://docs.aws.amazon.com/connect/latest/adminguide/user-management.html#add-users-in-bulk",
        "_blank"
      );
    }
  };

  const fileToBinary = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const binaryData = reader.result;
        resolve(binaryData);
      };
      reader.onerror = () => {
        reject(new Error("Unable to read the file as binary data"));
      };
      reader.readAsArrayBuffer(file);
    });
  };
  
  const handleBulkUsersCreation = async (data) => {
    const successMsgResponse = {
      'message': "Agents added successfully.",
      'password': 'RapidConnect123'
    }
    setSucessMessage(successMsgResponse);
    setTimeout(() => {
    setLoading(false);
  }, 2000);
    addAgents.status = true;
    // const payload = {
    //   fileKey: data.body + ".csv",
    //   uniqueId : homePageDetails.uId,
    //   regionName:companyDetails.awsAccountDetails.region,
    //   companyName: companyDetails.companyName,
    //   // awsAccountNumber: companyDetails.accountNumber,
    //   roleArn: companyDetails.awsAccountDetails.roleArn,
    //   accessUrl:companyDetails.companyAccessUrl,
    // };
    // const headers = {
    //   "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    // };
    // axiosInstance
    //   .post(endpoints.agentDetails.bulkUsersCreation, payload, {
    //     headers: headers,
    //   })
    //   .then((response) => {
    //     // console.log(JSON.parse(response.data.body));
    //     if (response.data.statusCode === 200) {
    //       // setSuccessMessage(JSON.parse(response.data.body));
    //       // setLoading(false);
    //       addAgents.status = true;
    //       setSuccessMessage({"message":"Agents added successfully"})
    //       setLoading(false);
    //     } else {
    //       const errorMessage = response.data;
    //       ErrorServiceLogger(
    //         errorMessage,
    //         companyDetails.companyAccessUrl,
    //         payload,
    //         endpoints.agentDetails.bulkUsersCreation
    //       );
    //     }
    //   })
    //   .catch((error) => {});
  };

  const handleFileUpload = async (e) => {
    setTimeout(() => {
      //     setLoading(false);
    handleBulkUsersCreation(null);
    // setTimeout(() => {
    //   setLoading(true);
    // }, 2000);
    setUploadClicked(true);
  setFilesDropped(true);
  setLoading(false);
   }, 3000); 

  //   setTimeout(() => {
  //     setLoading(false);
  //     setSuccessMessage({"message":"Agents added successfully"})
  //     setFilesDropped(true);
  // }, 5000); 
  
  //   const file = files[0];
  //   const binaryData = await fileToBinary(file);
  //   const headers = {
  //     "Content-Type": "application/csv",
  //   };

  //   axiosInstanceCSVUpload
  //     .post(endpoints.agentDetails.csvTemplateUpload, binaryData, {
  //       headers: headers,
  //     })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         handleBulkUsersCreation(response.data);
  //       }
  //     })
  //     .catch((error) => {});
  };

  const handleDownloadTemplateClick = async () => {
    try {
      const response = await axiosInstanceCSVDownload.get(
        endpoints.agentDetails.csvTemplateDownload,
        {
          responseType: "blob",
        }
      );
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "User_management.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("An error occurred while downloading the file.");
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles(droppedFiles);
    setFilesDropped(true);
    setUploadClicked(true);
  };

  const handleFileInputChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
    setFilesDropped(true);
  };
  const handleCancel = () => {
    setFiles([]); 
    setFilesDropped(false);
    setUploadClicked(false)
  };

  return (
    <MainTheme>
      <div className="csv-upload-page">
        <h3>Add Agents</h3>
        <br />
        <div className="input__group mb-2">
          <h5 className="csv_h5">
            1. Click to download template.{" "}
            <span
              className="download-template-link"
              onClick={handleDownloadTemplateClick}
            >
              (.csv template)
            </span>
            <br />
            <br />
            2. Add data to the template.{" "}
            <a
              className="download-template-link"
              href = "https://docs.aws.amazon.com/connect/latest/adminguide/user-management.html#add-users-in-bulk"
              target="_blank"
              rel="noopener noreferrer"
            >
              (Learn)
            </a>
            <br />
            <br />
            3. Save the csv file and upload.
            {showDetails && (
              <p className="details-paragraph">
                <a
                  href="https://docs.aws.amazon.com/connect/latest/adminguide/user-management.html#add-users-in-bulk"
                  target="_blank"
                  rel="noopener noreferrer"
                ></a>
              </p>
            )}
            <br />
            <br />
          </h5>
  
          <label className="upload-label">Upload CSV file:</label>

          <div
            className={`file-uploader ${dragging ? "dragging" : ""}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
             <input
              type="file"
              id="fileInput"
              className="file-input"
              onChange={handleFileInputChange}
              multiple
            />
            <UploadFileIcon fontSize="large"/>
 
            <label htmlFor="fileInput" className="file-label">
        {dragging ? "Drop files here" : "Drag & Drop files here "}
        {!filesDropped && (
          <span className="browse-button"> or click to select</span>
        )}
      </label>
      <input
        type="file"
        id="fileInput"
        className="file-input"
        onChange={handleFileInputChange}
      />
            <ul className="file-list">
              {files.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
            {!loading && files.length > 0 && !uploadClicked && (
        <button
          className={files.length > 0 ? "cancel-button" : "disable-button"}
          disabled={false} // Enable Cancel button when not uploading and files selected
          onClick={handleCancel}
        >
          Cancel
        </button>
      )}
            {loading ? (
              <CircularProgress
                className="loading-csv-button"
                sx={{ color: "#1a567a", width: "20px", height: "13px" }}
              />
            ) : (
              <button
              className={
                   files.length > 0 ? "cancel-button" : "disable-button"
                  }
              disabled={!filesDropped || uploadClicked}
             
              onClick={handleFileUpload}
            >
              Upload
            </button>
            )}
          </div>
        </div>
        <div>
            <p className="bulk-user-success">{successMessage.message}</p>
        </div>
        {/* {successMessage.message && (
        <div>
            <p className="bulk-user-success">{successMessage}</p>
        </div>
        )} */}
        {/* {successMessage.message && (
          <div>
            <p className="bulk-user-success">{successMessage}</p>
            <p className="default-password-container">
              Default password for agent login:{" "}
              <span
                className="password-text"
                onClick={() => copyToClipboard(`${successMessage.password}`)}
              >
                {successMessage.password}
                <div>
                  <ContentCopyRoundedIcon className="copy-icon" />
                </div>
              </span>
            </p>
           </div>
        )}
        <SnackBar
          openSnackBar={snackBarOpen}
          close={setSnackBarOpen}
          label={`Password: ${successMessage.password} Copied`}
        /> */}
      </div>
    </MainTheme>
  );
};
export default CsvUploadPage;