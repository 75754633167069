import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import MainTheme from "../mainTemplate";
import Label from "../../utilities/label";
import Title from "../../utilities/title";
import { axiosInstance } from "../../services/axiosInstance";
import { endpoints } from "../../services/endpoints";
import {
  claimPhoneNumberContext,
  companyDetailsContext,
  notFoundStatusContext,homePageContext
} from "../../context";
import { ErrorServiceLogger } from "../../services/errorServiceLogger";
import "./claimPhoneNumber.css";

export default function ClaimPhoneNumber() {
  const companyDetails = React.useContext(companyDetailsContext);
  const homePageDetails = React.useContext(homePageContext);
  let claimPhoneNumberForm = React.useContext(claimPhoneNumberContext);
  let notFoundStatus = React.useContext(notFoundStatusContext);
  const [countryList, setCountryList] = useState([]);
  const [claimCountry, setClaimCountry] = useState(
    localStorage.getItem("claimCountry") || ""
  );
  const [claimType, setClaimType] = useState(
    localStorage.getItem("claimType") || ""
  );
  const [claimPhoneNumber, setClaimPhoneNumber] = useState(
    localStorage.getItem("claimPhoneNumber") || ""
  );
  const [checkData, setCheckData] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  const [formattedPhoneNumbers, setformattedPhonenumbers] = useState([]);

  useEffect(() => {
    notFoundStatus.status = false;
    // getCountryList();
  }, []);

  useEffect(() => {
    if (claimCountry && claimType) {
      getClaimPhoneList(claimCountry, claimType);
    }
  }, [claimCountry, claimType]);

  useEffect(() => {
    if (claimPhoneNumber) {
      claimPhoneNumberForm.selectPhoneNumber = claimPhoneNumber;
    }
  }, [claimPhoneNumber]);

  // const getCountryList = async () => {
  //   await axiosInstance
  //     .get(endpoints.claimPhoneNumber.getCountryList)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setCountryList(response.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const getClaimPhoneList = async (country, type) => {
    const phone_number_list = { "phone_numbers": ["+14523698759","+16523698730","+18523698644","+15741236984","+17059874136"] }
    setCheckData(phone_number_list)
    // try {
    //   const payload = {
    //     type: type,
    //     alpha2Code: country,
    //     // accessUrl: companyDetails.companyAccessUrl,
    //     uniqueId : homePageDetails.uId,    
    //     regionName: companyDetails.awsAccountDetails.region,
    //     companyName: companyDetails.companyName,
    //     roleArn: companyDetails.awsAccountDetails.roleArn,
    //   };
    //   const headers = {
    //     "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    //   };
    //   const response = await axiosInstance.post(
    //     endpoints.claimPhoneNumber.claim,
    //     payload, {
    //       headers: headers,
    //     }
    //   );
    //   if (response.data.statusCode === 200) {
    //     setCheckData(JSON.parse(response.data.body));
    //   } else {
    //     const error = response.data.body;
    //     ErrorServiceLogger(
    //       error,
    //       companyDetails.companyAccessUrl,
    //       payload,
    //       endpoints.claimPhoneNumber.claim
    //     );
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
  };

  const handleOnChange = (e) => {
    const { id, value } = e.target;
    if (id === "select-country") {
      setClaimCountry(value);
      setErrorMessage({});
    } else if (id === "select-type") {
      if (!claimCountry) {
        setErrorMessage({ "select-country": "Please select country" });
        return;
      }
      setErrorMessage({});
      setClaimType(value);
    } else if (id === "select-phone-number") {
      if (!claimType) {
        setErrorMessage({ "select-type": "Please select claim type" });
        return;
      }
      setErrorMessage({});
      setClaimPhoneNumber(value);
    }
  };

  useEffect(() => {
    let formattedPhoneNumbers =
      checkData && checkData.phone_numbers
        ? checkData.phone_numbers.map((number) => ({
            label: number,
            value: number,
          }))
        : [];
    setformattedPhonenumbers(formattedPhoneNumbers);
  }, [checkData]);

  const claimPhoneNumberFields = React.useMemo(
    () => [
      {
        label: "Select country",
        value: claimCountry,
        // options: countryList.map((item) => ({
        //   value: item.alpha2Code,
        //   label: item.name,
        // })),
        options : [
          {
            value : "US", label :"United States"
          },
        ],
        id: "select-country",
        errorMessage: errorMessage["select-country"],
      },
      {
        label: "Select type",
        value: claimType,
        options: [
          { value: "DID", label: "DID" },
          { value: "TOLL_FREE", label: "TOLL-FREE" },
        ],
        id: "select-type",
        errorMessage: errorMessage["select-type"],
      },
      {
        label: "Select phone number",
        value: claimPhoneNumber,
        options: formattedPhoneNumbers,
        id: "select-phone-number",
        errorMessage: errorMessage["select-phone-number"],
      },
    ],
    [
      claimCountry,
      countryList,
      errorMessage,
      claimType,
      claimPhoneNumber,
      formattedPhoneNumbers,
    ]
  );

  return (
    <MainTheme>
      <Grid container spacing={1} className="claim-phone-container-section">
        <Grid item xs={12}>
          <Title title={"Claim phone number"} />
        </Grid>
        {claimPhoneNumberFields.map((field, index) => (
          <Grid item xs={12} key={index} className="input-div-class">
            <Label title={field.label} />
            <select
              id={field.id}
              className="text-input"
              onChange={handleOnChange}
              value={field.value || ""}
            >
              <option value="" disabled>
                {`${field.label}`}
              </option>
              {field.options && field.options.length > 0 ? (
                field.options.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))
              ) : (
                <option className="loading-text">Loading...</option>
              )}
            </select>

            {field.errorMessage && (
              <Typography className="error-text">
                {field.errorMessage}
              </Typography>
            )}
          </Grid>
        ))}
      </Grid>
    </MainTheme>
  );
}
